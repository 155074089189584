export default [
  {
    name: "Baden-Württemberg",
    title: "Baden-Württemberg",
    slug: "baden-wuerttemberg",
    nutsCode: "DE1",
    pricesLink: "/heizoelpreise/bundeslaender/baden-wuerttemberg",
  },
  {
    name: "Bayern",
    title: "Bayern",
    slug: "bayern",
    nutsCode: "DE2",
    pricesLink: "/heizoelpreise/bundeslaender/bayern",
  },
  {
    name: "Berlin",
    title: "Berlin",
    slug: "berlin",
    nutsCode: "DE3",
    pricesLink: "/heizoelpreise/heizoelpreise-berlin-10115",
  },
  {
    name: "Brandenburg",
    title: "Brandenburg",
    slug: "brandenburg",
    nutsCode: "DE4",
    pricesLink: "/heizoelpreise/bundeslaender/brandenburg",
  },
  {
    name: "Bremen",
    title: "Bremen",
    slug: "bremen",
    nutsCode: "DE5",
    pricesLink: "/heizoelpreise/heizoelpreise-bremen-27568",
  },
  {
    name: "Hamburg",
    title: "Hamburg",
    slug: "hamburg",
    nutsCode: "DE6",
    pricesLink: "/heizoelpreise/heizoelpreise-hamburg-20095",
  },
  {
    name: "Hessen",
    title: "Hessen",
    slug: "hessen",
    nutsCode: "DE7",
    pricesLink: "/heizoelpreise/bundeslaender/hessen",
  },
  {
    name: "Mecklenburg-Vorpommern",
    title: "Mecklenburg-Vorpommern",
    slug: "mecklenburg-vorpommern",
    nutsCode: "DE8",
    pricesLink: "/heizoelpreise/bundeslaender/mecklenburg-vorpommern",
  },
  {
    name: "Niedersachsen",
    title: "Niedersachsen",
    slug: "niedersachsen",
    nutsCode: "DE9",
    pricesLink: "/heizoelpreise/bundeslaender/niedersachsen",
  },
  {
    name: "Nordrhein-Westfalen",
    title: "Nordrhein-Westfalen",
    slug: "nordrhein-westfalen",
    nutsCode: "DEA",
    pricesLink: "/heizoelpreise/bundeslaender/nordrhein-westfalen",
  },
  {
    name: "Rheinland-Pfalz",
    title: "Rheinland-Pfalz",
    slug: "rheinland-pfalz",
    nutsCode: "DEB",
    pricesLink: "/heizoelpreise/bundeslaender/rheinland-pfalz",
  },
  {
    name: "Saarland",
    title: "Saarland",
    slug: "saarland",
    nutsCode: "DEC",
    pricesLink: "/heizoelpreise/bundeslaender/saarland",
  },
  {
    name: "Sachsen",
    title: "Sachsen",
    slug: "sachsen",
    nutsCode: "DED",
    pricesLink: "/heizoelpreise/bundeslaender/sachsen",
  },
  {
    name: "Sachsen-Anhalt",
    title: "Sachsen-Anhalt",
    slug: "sachsen-anhalt",
    nutsCode: "DEE",
    pricesLink: "/heizoelpreise/bundeslaender/sachsen-anhalt",
  },
  {
    name: "Schleswig-Holstein",
    title: "Schleswig-Holstein",
    slug: "schleswig-holstein",
    nutsCode: "DEF",
    pricesLink: "/heizoelpreise/bundeslaender/schleswig-holstein",
  },
  {
    name: "Thüringen",
    title: "Thüringen",
    slug: "thueringen",
    nutsCode: "DEG",
    pricesLink: "/heizoelpreise/bundeslaender/thueringen",
  },
] as const;
